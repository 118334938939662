import { IconName } from '@/elements/Icon/types';
import { ConsumerTypesEnum, consumerTypePathBase } from 'uibook';

type SocialMediaLink = {
  icon: IconName;
  link: string;
};

export const footerSocialMediaLinks: SocialMediaLink[] = [
  {
    icon: 'Facebook',
    link: 'https://www.facebook.com/joinraylo/',
  },
  {
    icon: 'Instagram',
    link: 'https://www.instagram.com/raylotech',
  },
  {
    icon: 'Twitter',
    link: 'https://twitter.com/raylo',
  },
];

type FooterListsProps = {
  consumerIsBusiness: boolean;
  isRayloPay: boolean;
};
export const getFooterLists = ({ consumerIsBusiness, isRayloPay }: FooterListsProps) => {
  if (isRayloPay) {
    return {
      footerListOne: {
        title: 'Flex',
        links: [
          {
            label: 'How It Works',
            link: '/',
          },
          {
            label: 'PS5 Consoles',
            link: '/products?category=consoles',
          },
          {
            label: 'PS5 Bundles',
            link: '/products?category=bundles',
          },
          {
            label: 'PS5 Accessories',
            link: '/products?category=accessories',
          },
        ],
      },
      footerListTwo: {
        title: 'Raylo',
        links: [
          {
            label: 'About',
            link: 'https://www.raylo.com/about',
          },
          {
            label: 'Help',
            link: 'https://help.raylo.com/',
          },
          {
            label: 'Contact Us',
            link: 'https://help.raylo.com/en/articles/3271547-contact-raylo',
          },
        ],
      },
    };
  }
  return {
    footerListOne: {
      title: 'Raylo',
      links: [
        {
          label: 'My Account',
          link: 'https://app.raylo.com/login?_ga=2.244162309.1234187286.1648466711-1412900303.1643731306&_gac=1.128649982.1647352336.CjwKCAjw8sCRBhA6EiwA6_IF4T5ULLG4cUeaWZgjpyMtdEqx50wWKdJgbZkr3e2dviPbJmIEYPMG0RoCXXsQAvD_BwE',
        },
        {
          label: 'Sustainability',
          link: 'https://www.raylo.com/sustainability',
        },
        {
          label: 'How it works',
          link: `https://www.raylo.com${consumerIsBusiness ? consumerTypePathBase[ConsumerTypesEnum.BUSINESS] : '/'}#how-it-works`,
        },
        {
          label: 'Pricing',
          link: 'https://www.raylo.com/#pricing',
        },
        {
          label: 'Blog',
          link: 'https://www.raylo.com/blog',
        },
        {
          label: 'New phones',
          link: 'https://www.raylo.com/brands/new',
        },
        {
          label: 'Refurbished phones',
          link: 'https://www.raylo.com/brands/refurbished',
        },
        {
          label: 'About',
          link: 'https://www.raylo.com/post/iphone-upgrade-programme',
        },
      ],
    },
    footerListTwo: {
      title: 'Customer Service',
      links: [
        {
          label: 'Help',
          link: 'https://help.raylo.com/',
        },
        {
          label: 'Contact Us',
          link: 'https://help.raylo.com/en/articles/3271547-contact-raylo',
        },
        {
          label: 'Careers',
          link: 'https://jobs.eu.lever.co/raylo',
        },
        {
          label: 'Guides',
          link: 'https://www.raylo.com/post',
        },
        {
          label: 'Legal',
          link: 'https://www.raylo.com/legal',
        },
        {
          label: 'Students',
          link: 'https://raylo.connect.studentbeans.com/uk',
        },
      ],
    },
  };
};
